import { useEffect, useState } from 'react';

export enum Browser {
  Chrome = 'Chrome',
  Safari = 'Safari',
  Opera = 'Opera',
  Firefox = 'Firefox',
  Android = 'Android',
  Not_defined = 'Not_defined',
}

export default function useBrowserDetect() {
  const [browser, setBrowser] = useState(Browser.Not_defined);

  useEffect(() => {
    // const chromeAgent = navigator.userAgentData.brands.some('chrome');
    const chromeAgent = navigator.userAgent.toLowerCase().indexOf('chrome') != -1;
    if (chromeAgent) {
      setBrowser(Browser.Chrome);
    } else if (navigator.userAgent.toLowerCase().indexOf('safari') !== -1 && !chromeAgent) {
      setBrowser(Browser.Safari);
    } else if (
      (navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1 &&
      !chromeAgent
    ) {
      setBrowser(Browser.Opera);
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      setBrowser(Browser.Firefox);
    } else if (navigator.userAgent.toLowerCase().indexOf('android') !== -1) {
      setBrowser(Browser.Android);
    } else {
      setBrowser(Browser.Not_defined);
    }
  }, []);

  return browser;
}
