import * as React from 'react';
import { Link, navigate } from 'gatsby';
import styles from './subnav.module.css';
import compositeStyles from './compositeSubnav.module.css';
import ReactSelect from 'react-select';
import find from 'lodash/find';

interface Props {
  list: ReadonlyArray<{
    title: string;
    to: string;
  }>;
  className?: string;
  pathname: string;
  isComposite?: boolean;
}

function SubNavigation({ list, pathname, className, isComposite }: Props) {
  const options = React.useMemo(
    () => list.map(({ title, to }) => ({ label: title, value: to })),
    []
  );

  const handleChange = React.useCallback(({ value }) => {
    navigate(value);
  }, []);

  const value = React.useMemo(() => find(options, (o) => o.value === pathname), [pathname]);
  const style = isComposite ? compositeStyles : styles;

  return (
    <div className={style.container}>
      <div className={className}>
        <ReactSelect
          value={value}
          options={options}
          onChange={handleChange}
          className={style.routeBtn}
          classNamePrefix={'routeBtn'}
          isSearchable={false}
          maxMenuHeight={500}
          styles={{
            option: (base) => ({
              ...base,
              cursor: 'pointer',
            }),
            control: (base) => ({
              ...base,
              cursor: 'pointer',
            }),
          }}
        />
        <ul className={style.list}>
          {list.map((e) => (
            <li className={style.item} key={e.to}>
              <Link className={style.link} activeClassName={style.active} to={e.to}>
                {e.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SubNavigation;
