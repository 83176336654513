import React, { useState } from 'react';
import clsx from 'clsx';
//hooks
import useBrowserDetect, { Browser } from 'hooks/useBrowserDetect';
//styles
import styles from './video.module.css';
//icons
import StopBtn from '../../../images/career/stop-btn.inline.svg';

interface IVideo {
  poster: string;
  posterWithPlayBtn: string;
  video: string;
  containerStyles?: string;
  videoStyles?: string;
  isPreload?: 'none' | 'auto' | 'metadata';
}

const Video = ({
  poster,
  posterWithPlayBtn,
  video,
  containerStyles,
  videoStyles,
  isPreload = 'none',
}: IVideo) => {
  const [isBtnStopVisible, setBtnStopVisible] = useState(false);

  const hideStopBtn = () => {
    setBtnStopVisible(false);
  };
  const showStopBtn = () => {
    setBtnStopVisible(true);
  };

  const isSafari = useBrowserDetect() === Browser.Safari;

  return (
    <div className={clsx(styles.container, containerStyles)}>
      <video
        width="100%"
        preload={isPreload}
        controls
        poster={isSafari ? `${poster}` : `${posterWithPlayBtn}`}
        onClick={(e) => (e.target as HTMLVideoElement).play()}
        onPlaying={showStopBtn}
        onPause={hideStopBtn}
        className={videoStyles ?? styles.video}
      >
        <source src={`${process.env.GATSBY_STRAPI_CDN}/video/${video}.webm`} type="video/webm" />
        <source src={`${process.env.GATSBY_STRAPI_CDN}/video/${video}.mp4`} type="video/mp4" />
      </video>
      {isBtnStopVisible && (
        <div className={styles.stopBtnContainer}>
          <StopBtn className={styles.stopBtn} />
        </div>
      )}
    </div>
  );
};
export default Video;
