import { graphql, useStaticQuery } from 'gatsby';

export default function useHeaderContact() {
  const {
    strapiBaseSetting: { header_contact },
  } = useStaticQuery(graphql`
    query {
      strapiBaseSetting {
        header_contact {
          calendly_link
          link_name
        }
      }
    }
  `);

  return header_contact;
}
