import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//styles
import styles from '../martech.module.css';
// hooks
import { useFaqData } from 'hooks/useFaqData';
// components
import Layout from '../components/layout';
import Blockquote from '../../services/components/blockquote';
import Booking from 'components/feedback';
import Technology from 'components/technology';
import Recognition from '../../recognition';
import Video from '../../layout/video';
import { ConverHero, Hypeboard, Adact } from 'components/carousel';
//data
import integrations from './integrations.json';
import areasOfExpertise from './ areasOfExpertise.json';
import modernizeStack from './modernizeStack.json';
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';

const SwiperSlider = loadable(() => import('components/swiper-slider'));
const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));
interface Props {
  location: Location;
}
interface ISolutionItem {
  title: string;
  text: string | JSX.Element;
  imageSrc: string;
}
interface IUpdateItem {
  title: string;
  titleForMobiles: string;
  text: string;
  imageSrc: string;
}

function Martech({ location }: Props) {
  const data = useStaticQuery(graphql`
    query {
      kalev: file(relativePath: { eq: "kalev-adact.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adact: file(relativePath: { eq: "img/adact-black-logo.svg" }) {
        publicURL
      }
      buildNewProdMob: file(relativePath: { eq: "build-new-product.svg" }) {
        publicURL
      }
      buildNewProdDesk: file(relativePath: { eq: "build-new-product-desk.svg" }) {
        publicURL
      }
      solution1: file(relativePath: { eq: "industries/solution1.svg" }) {
        publicURL
      }
      solution2: file(relativePath: { eq: "industries/solution2.svg" }) {
        publicURL
      }
      solution3: file(relativePath: { eq: "industries/solution3.svg" }) {
        publicURL
      }
      solution4: file(relativePath: { eq: "industries/solution4.svg" }) {
        publicURL
      }
      integrationsMob: file(relativePath: { eq: "industries/integrations-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      integrationsDesk: file(relativePath: { eq: "industries/integrations.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      modernizeStack: file(relativePath: { eq: "industries/modernize-stack.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      modernizeStackMob: file(relativePath: { eq: "industries/modernize-stack-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      update1: file(relativePath: { eq: "industries/update-legacy-1.svg" }) {
        publicURL
      }
      update2: file(relativePath: { eq: "industries/update-legacy-2.svg" }) {
        publicURL
      }
      update3: file(relativePath: { eq: "industries/update-legacy-3.svg" }) {
        publicURL
      }
      rafal: file(relativePath: { eq: "rafal-dyrda.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      condogenie: file(relativePath: { eq: "condogenie-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wagepointLogo: file(relativePath: { eq: "wagepoint-logo.svg" }) {
        publicURL
      }
      condogenieLogo: file(relativePath: { eq: "condogenie-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shradRao: file(relativePath: { eq: "shrad-rao.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      developSoftware: file(relativePath: { eq: "industries/develop-software-desck.svg" }) {
        publicURL
      }
      developSoftwareMob: file(relativePath: { eq: "industries/develop-software-mob.svg" }) {
        publicURL
      }
      eugeneUrsalov: file(relativePath: { eq: "industries/eugene-ursalov.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logoCasers: file(relativePath: { eq: "industries/logo-casers.svg" }) {
        publicURL
      }
      svetaFotoForSafari: file(relativePath: { eq: "sveta-about-martech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svetaFoto: file(relativePath: { eq: "sveta-about-martech-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      arrow: file(relativePath: { eq: "arrow.svg" }) {
        publicURL
      }
    }
  `);

  const faqData = useFaqData('allStrapiIndustry', 'Martech');
  const breakpoint = useBreakpoint();

  const solutions: ISolutionItem[] = useMemo(
    () => [
      {
        title: 'Tailor \r\n the user interface',
        text: 'Customize the user interface to deliver better experiences to corporate clients while meeting their unique requirements.',
        imageSrc: data.solution1.publicURL,
      },
      {
        title: 'Provide \r\n custom integration',
        text: "Build integrations that flawlessly connect your platform with your customers'internal systems and databases.",
        imageSrc: data.solution2.publicURL,
      },
      {
        title: 'Build \r\n a white-label product',
        text: (
          <>
            Let your customers fully customize your <strong>martech software</strong>, including
            logos, colors, user interface, workflows, and more. martech development services.
          </>
        ),
        imageSrc: data.solution3.publicURL,
      },
      {
        title: 'Develop \r\n interactive templates',
        text: 'Add more use cases to your template library such as games for marketing gamification campaigns.',
        imageSrc: data.solution4.publicURL,
      },
    ],
    []
  );
  const updates: IUpdateItem[] = useMemo(
    () => [
      {
        title: 'Enhance your \r\n existing system',
        titleForMobiles: 'Enhance your existing system',
        text: 'Move data from apps to the central warehouse and vice versa. Optimize your pipeline to smoothly collect, transform, and transfer data between sources.',
        imageSrc: data.update1.publicURL,
      },
      {
        title: 'Automate new \r\n business processes',
        titleForMobiles: 'Automate new business processes',
        text: 'Migrate your legacy  data warehouse to the cloud and scale easily. Reduce costs and increase your warehouse capacity when the volume of data grows.',
        imageSrc: data.update2.publicURL,
      },
      {
        title: 'Move a legacy \r\n solution to the cloud',
        titleForMobiles: 'Move a legacy solution to the cloud',
        text: 'Turn raw data into a ready-to-use format and make it available for analytics. Access cleansed, accurate, up-to-date, and properly-formatted data.',
        imageSrc: data.update3.publicURL,
      },
    ],
    []
  );

  return (
    <Layout
      title="Martech&Adtech Software Development Services"
      subtitle={
        <>
          Scale your digital marketing product, develop new tools, and build a better experience for
          customers using our expert <strong>martech development services.</strong>
        </>
      }
      linkText="Contact us"
      metaData={{ main: meta.marTech, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.sectionTitle, styles.titleMargins)}>We’ve got your back</h2>
          <p className="subtitle">
            If you need help building new features, modernizing your data stack, upgrading your
            legacy system, or extending your team with top-notch martech engineers ‒ Brocoders can
            do all that and even more. In a nutshell, we help you cope with marketing technology
            challenges.
          </p>
          <h2 className={clsx(styles.sectionSubitle, styles.subtitleMargins)}>
            Build a high-load marketing technology solution
          </h2>
          <p className={clsx(styles.subitleText, styles.paragrafMargins)}>
            Brocoders can help you develop a marketing automation platform capable of handling
            massive amounts of data – think millions of requests! Cut it simple, on Black Friday,
            when your traffic swings wildly reaching 300,000 visitors per hour…your site won’t go
            down.
          </p>
        </div>
      </section>
      <div className={styles.quotes}></div>
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        isRating={true}
        logoImg={data.adact.publicURL}
        blockquoteClassName={styles.blockquote}
        isLogo={true}
        text='"We’ve tested the product and have acquired over two million interactions without experiencing downtime or bug-related issues, so I can confidently say that Brocorders has met our success criteria."'
        author={
          <React.Fragment>
            <b>Kalev Kärpuk</b>
            <br />
            CEO &amp; Founder,{' '}
            <a href="https://adact.me/" target="_blank" className="link">
              Adact
            </a>
          </React.Fragment>
        }
      />
      <section className={clsx('section', styles.videoSection)}>
        <div className={clsx('inner-container', styles.videoThumb)}>
          <Video
            poster={data.svetaFotoForSafari.childImageSharp.fluid.srcWebp}
            posterWithPlayBtn={data.svetaFoto.childImageSharp.fluid.srcWebp}
            video="adact-final"
          />
        </div>
      </section>
      <section className={styles.sectionOffer}>
        <div className={clsx('inner-container', styles.containerOffer)}>
          <picture>
            <source
              srcSet={data.buildNewProdMob.publicURL}
              media="(max-width: 768px)"
              type="image/svg+xml"
            />
            <source
              srcSet={data.buildNewProdDesk.publicURL}
              media="(min-width: 769px)"
              type="image/svg+xml"
            />
            <img
              src={data.buildNewProdDesk.publicURL}
              className={styles.offerIcon}
              alt="picture of building new product"
            />
          </picture>
          <Link
            id={identificators.START_PROJECT}
            className={clsx('btn btn_60 btn__black', styles.wideLink, styles.linkOffer)}
            to="/get-in-touch/"
          >
            Start your project
          </Link>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.sectionSubitle, styles.subtitleServe)}>
            Serve enterprise customers
          </h2>
          <p className="subtitle">
            Create customized <strong>martech solutions</strong> for big clients with special needs.
            Tweak their user interface, implement required integrations, or offer a white-label
            product ‒ we can help you adjust your core platform to the operational needs of your
            enterprise clients.
          </p>
          <ul className={styles.martechSolutionsList}>
            {solutions.map(({ title, text, imageSrc }) => (
              <li className={styles.solutionItem} key={title}>
                <h3 className={styles.solutionName}>{title}</h3>
                <img src={imageSrc} className={styles.solutionImg} alt=""></img>
                <p className={styles.solutionText}>{text}</p>
              </li>
            ))}
          </ul>
          <Link
            id={identificators.CUSTOMISE_YOUR_SOLUTION}
            className={clsx('btn btn_60 btn__red', styles.linkCustomize)}
            to="/get-in-touch/"
          >
            Customize your solution
          </Link>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.sectionSubitle, styles.titleMargins)}>
            Add new functionality or integrations
          </h2>
          <p className="subtitle">
            Build APIs using REST and GraphQL to ensure seamless integration with marketing systems
            that perfectly fit your current workflow. Implement new features to improve user
            experience and forge a successful relationship with customers.
          </p>
          <div className={styles.integrationsBox}>
            <ul className={styles.integrationsList}>
              {integrations.slice(0, 4).map(({ title, description }) => (
                <li className={styles.integrationItem} key={title}>
                  <h3 className={styles.title}>{title}</h3>
                  <p className={styles.text}>{description}</p>
                </li>
              ))}
            </ul>
            <div className={styles.integrationImg}>
              {breakpoint.s ? (
                <Img fluid={data.integrationsMob.childImageSharp.fluid} alt="integrations" />
              ) : (
                <Img fluid={data.integrationsDesk.childImageSharp.fluid} alt="integrations" />
              )}
            </div>
            <ul className={styles.integrationsList}>
              {integrations.slice(4).map(({ title, description }) => (
                <li className={styles.integrationItem} key={title}>
                  <h3 className={styles.title}>{title}</h3>
                  <p className={styles.text}>{description}</p>
                </li>
              ))}
            </ul>
          </div>
          <Link
            id={identificators.ENHANCE_YOUR_PLATFORM}
            className={clsx('btn btn_60 btn__red', styles.linkCustomize)}
            to="/get-in-touch/"
          >
            Enhance your platform
          </Link>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.sectionSubitle, styles.titleMargins)}>
            Modernize your data stack
          </h2>
          <p className="subtitle">
            Build a suite of tools used for data integration in the cloud to improve accessibility,
            scalability, and data management, and lower the technical barrier for analysts and
            business users.
          </p>
          <div className={styles.integrationsBox}>
            <ul className={styles.integrationsList}>
              {modernizeStack.slice(0, 2).map(({ title, description }) => (
                <li className={styles.integrationItem} key={title}>
                  <h3 className={styles.title}>{title}</h3>
                  <p className={styles.text}>{description}</p>
                </li>
              ))}
            </ul>
            <div className={styles.integrationImg}>
              {breakpoint.s ? (
                <Img fluid={data.modernizeStackMob.childImageSharp.fluid} alt="" />
              ) : (
                <Img fluid={data.modernizeStack.childImageSharp.fluid} alt="" />
              )}
            </div>
            <ul className={styles.integrationsList}>
              {modernizeStack.slice(2).map(({ title, description }) => (
                <li className={styles.integrationItem} key={title}>
                  <h3 className={styles.title}>{title}</h3>
                  <p className={styles.text}>{description}</p>
                </li>
              ))}
            </ul>
          </div>
          <Link
            id={identificators.BUILD_A_DATA_STACK}
            className={clsx('btn btn_60 btn__red', styles.linkCustomize)}
            to="/get-in-touch/"
          >
            Build a data stack
          </Link>
        </div>
      </section>
      <section className={clsx('section', styles.modernizeBox)}>
        <div className="inner">
          <h2 className={clsx(styles.sectionSubitle, styles.titleMargins)}>
            Update your legacy system
          </h2>
          <p className="subtitle">
            Modernize your outdated software to enhance performance and scalability while reducing
            the cost for software support. We can help you refactor, replatform, or rehost ‒
            whatever you need to improve business efficiency.
          </p>
          <ul className={styles.legacyUpdateList}>
            {updates.map(({ title, titleForMobiles, text, imageSrc }) => (
              <li className={styles.legacyUpdateItem} key={titleForMobiles}>
                <img src={imageSrc} className={styles.legacyUpdateImg} alt=""></img>
                <h3 className={styles.title}>{breakpoint.s ? titleForMobiles : title}</h3>
                <p className={styles.text}>{text}</p>
              </li>
            ))}
          </ul>
          <Link
            id={identificators.MODERNIZE_YOUR_APP}
            className={clsx('btn btn_60 btn__red', styles.linkCustomize)}
            to="/get-in-touch/"
          >
            Modernize your app
          </Link>
        </div>
      </section>
      <section className={clsx('section', styles.technicalStackBox)}>
        <div className="inner">
          <h2 className={styles.sectionSubitle}>
            Ramp up your team with top
            <strong className={clsx(styles.sectionSubitle, styles.emphasisText)}>
              {' '}
              martech developers
            </strong>
          </h2>
          <p className={clsx('subtitle', styles.rampUpMargin)}>
            Get remote martech developers quickly when your team’s workload is particularly heavy.
            Node.JS, MongoDB, React Native ‒ we are experts in the most popular technologies and
            frameworks to help you build and scale your product.
          </p>
          <div className={styles.technicalStack}>
            <Technology
              list={[
                'react',
                'nodejs',
                'reactNative',
                'expressJs',
                'gatsby',
                'mongo',
                'blockchain',
                'azure_aws',
              ]}
              listStyle={styles.techList}
            />
          </div>
          <Link
            id={identificators.HIRE_DEVELOPERS}
            className={clsx('btn btn_60 btn__red', styles.linkCustomize)}
            to="/get-in-touch/"
          >
            Hire developers
          </Link>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className={clsx(styles.sectionSubitle, styles.marginsTitle)}>
            Marketing automation projects we’ve delivered
          </h2>
          <p className="subtitle">
            We implement marketing automation solutions for SaaS startups and mid-size companies.
            Here are just a few of them.
          </p>
        </div>
      </section>

      <SwiperSlider>
        <Adact />
        <ConverHero />
        <Hypeboard />
      </SwiperSlider>

      <section className={clsx('section', styles.expertiseSection)}>
        <div className="inner">
          <h3 className={styles.sectionSubitle}>Our areas of expertise in marketing automation</h3>
          <p className={clsx('subtitle', styles.subtitle)}>
            {
              'Whether it’s marketing gamification software, a customer engagement platform,  \r\n or an analytics tool, we have experience building different types of martech products.'
            }
          </p>
          <ul className={styles.expertiseContainer}>
            {areasOfExpertise.map(({ title, text }) => (
              <li className={styles.expertiseItem} key={title}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>{text}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <ReviewSlider swiperClassName={styles.blockquoteSwiper}>
        <Blockquote
          text="“Overall we are very happy with the Brocoders team. We would not have been able to deliver on our short timelines without them showing up for us in the way that they did. Period.”"
          img={data.shradRao.childImageSharp.fluid}
          mainIdea="We deliver, even on short timelines"
          isLogo={true}
          innerClassName={styles.innerBlockquoteWagepoint}
          logoImg={data.wagepointLogo.publicURL}
          withTitle
          isRating
          author={
            <React.Fragment>
              <strong>Shrad Rao </strong> <br />
              CEO,{' '}
              <a
                href="https://wagepoint.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={''}
              >
                Wagepoint Inc
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          text="“Upoun launching the project we were able to increase client satisfaction and provide our clients what they were asking for, improve their workflow as well as make the software more powerful and easier to use then the previous software that was developed.”"
          mainIdea="A genuine value to the project"
          img={data.rafal.childImageSharp.fluid}
          isLogo={true}
          logoImg={data.condogenieLogo.childImageSharp.fluid.src}
          withTitle
          innerClassName={styles.innerBlockquoteCondogenie}
          isRating
          author={
            <React.Fragment>
              <strong>Rafal Dyrda</strong> <br />
              CEO,{' '}
              <a href="https://condogenie.com/" target="_blank" className="link">
                Condogenie
              </a>
            </React.Fragment>
          }
        />
        <Blockquote
          text="“I was impressed by Brocoders startup mentality and ability to think long-term, taking a proactive approach in discussing the future of my project. It seems that they take my project personally and aspire to make it succeed. I felt that they were truly interested in the idea and wanted to make the best out of it.”"
          img={data.eugeneUrsalov.childImageSharp.fluid}
          mainIdea="Ability to think long-term"
          isLogo={true}
          logoImg={data.logoCasers.publicURL}
          blockquoteCaption={
            breakpoint.s ? 'See what \r\n our customers say' : 'See what our customers say'
          }
          innerClassName={styles.innerBlockquoteCasers}
          isRating
          author={
            <React.Fragment>
              <strong>Eugene Ursalov </strong> <br />
              CEO,{' '}
              <a
                href="https://casers.org/"
                target="_blank"
                rel="noopener noreferrer"
                className={''}
              >
                Casers
              </a>
            </React.Fragment>
          }
        />
      </ReviewSlider>
      <div className={styles.forwardToClutch}>
        <a
          href="https://clutch.co/profile/brocoders#focus"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkToClutch}
        >
          Read more client reviews on Clutch
        </a>
      </div>
      <section className={clsx('section', styles.recognitionBox)}>
        <div className="inner">
          <h2 className={clsx(styles.sectionTitle, styles.marginsRecognition)}>Recognition</h2>
          <Recognition />
        </div>
      </section>
      <div className={styles.developSoftwareBox}>
        {breakpoint.s ? (
          <img
            src={data.developSoftwareMob.publicURL}
            className={styles.developSoftwareImg}
            alt=""
          />
        ) : (
          <img src={data.developSoftware.publicURL} alt="" />
        )}
        <div className={styles.soundChallangeBox}>
          {!breakpoint.m && (
            <img src={data.arrow.publicURL} className={styles.arrowIcon} alt="icon of arrow" />
          )}
          <Link
            id={identificators.CONTACT_US}
            className={clsx('btn btn_60 btn__black wideBtn', styles.linkDevelopSoftware)}
            to="/get-in-touch/"
          >
            Contact us
          </Link>
        </div>
      </div>
      <Booking />
    </Layout>
  );
}
export default Martech;
