import * as React from 'react';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//types
import { ImageFluid } from 'components/Types';
//styles
import styles from './bq.module.css';
import StarsIcon from '../../../../images/career/stars.inline.svg';

interface Props {
  text: string;
  author: React.ReactNode;
  mainIdea?: string;
  img?: ImageFluid;
  innerClassName?: string;
  blockquoteClassName?: string;
  downloadReviewLink?: string;
  link?: {
    to: string;
    title: string;
  };
  linkText?: string;
  isRating?: boolean;
  isLogo?: boolean;
  logoImg?: string;
  logoStyles?: string;
  blockquoteCaption?: string;
  withTitle?: boolean;
  isWaveOnTop?: boolean;
}

function Blockquote({
  img,
  text,
  mainIdea,
  author,
  link,
  logoImg,
  innerClassName,
  blockquoteClassName,
  downloadReviewLink,
  isRating = false,
  isLogo = false,
  withTitle = false,
  blockquoteCaption,
  logoStyles,
  isWaveOnTop = false,
  linkText = 'Download full review',
}: Props) {
  const caption = (() => {
    const breakpoint = useBreakpoint();
    if (blockquoteCaption) return blockquoteCaption;
    if (withTitle) {
      return breakpoint.s ? 'See what \r\n our customers say' : 'See what our customers say';
    } else return null;
  })();

  return (
    <section className={clsx(blockquoteClassName, isWaveOnTop && styles.waveContainer)}>
      <div className={clsx(styles.innerContainer, innerClassName, isWaveOnTop && 'section gray')}>
        <blockquote className={styles.blockquote}>
          {!!caption && <p className={styles.blockquoteCaption}>{caption}</p>}
          <figure className={styles.figure}>
            {img && <Img fluid={img} className={styles.ava} alt="avatar" />}
            <div>
              <div
                className={
                  isLogo
                    ? styles.caption
                    : !!mainIdea
                    ? clsx(styles.caption, styles.withoutLogo)
                    : clsx(styles.caption, styles.withoutLogoAndIdea)
                }
              >
                {isLogo ? (
                  <img src={logoImg} className={logoStyles} alt="logo" />
                ) : (
                  !!mainIdea && <p className={styles.mainIdea}>{mainIdea}</p>
                )}
                {isRating && (
                  <div className={styles.rating}>
                    <span className={styles.ratingNumber}>5.0</span>
                    <StarsIcon style={{ height: '18px', width: 'auto' }} />
                  </div>
                )}
              </div>
              {isLogo && mainIdea && <p className={styles.mainIdea}>{mainIdea}</p>}
              <p className={styles.text}>{text}</p>
              {downloadReviewLink && (
                <a
                  target="_blank"
                  href={downloadReviewLink}
                  className={clsx(styles.link, styles.downloadLink)}
                  rel="noopener noreferrer"
                >
                  {linkText}
                </a>
              )}
              <figcaption className={styles.figcaption}>{author}</figcaption>
              {link ? (
                <a href={link.to} className={styles.link} rel="noopener noreferrer">
                  {link.title}
                </a>
              ) : null}
            </div>
          </figure>
        </blockquote>
      </div>
    </section>
  );
}

export default Blockquote;
