import React, { useRef, useEffect, useState } from 'react';
import styles from './wave.module.css';

interface iWave {
  color: string;
  opacity: number;
}

function hex2rgb(hex: string, opacity: number = 0.9) {
  hex = hex.trim();
  hex = hex[0] === '#' ? hex.substr(1) : hex;
  const bigint = parseInt(hex, 16),
    h = [];
  if (hex.length === 3) {
    h.push((bigint >> 4) & 255);
    h.push((bigint >> 2) & 255);
  } else {
    h.push((bigint >> 16) & 255);
    h.push((bigint >> 8) & 255);
  }
  h.push(bigint & 255);
  return `rgb(${h.join()}, ${opacity})`;
}

const Wave = ({ color, opacity }: iWave) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasLineRef = useRef<HTMLCanvasElement | null>(null);
  const [isSmallDevice, onChange] = useState(
    typeof window !== 'undefined' ? window.innerWidth < 1024 : true
  );

  const coloredWave = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext('2d');
    const speed = 2500;
    if (wrapperRef.current) canvas.height = wrapperRef.current.offsetHeight;

    function drawColoredWave(delta: number) {
      if (window.innerWidth > 1024) {
        requestAnimationFrame(drawColoredWave);
      }
      canvas.width = window.innerWidth;
      if (ctx) {
        const randomLeft = Math.abs(Math.pow(Math.sin(delta / speed), 2)) * 50 + 50;
        const randomRight = Math.abs(Math.pow(Math.sin(delta / speed + 500 + 100), 2)) * 200;
        const randomLeftConstraint = Math.abs(Math.pow(Math.sin(delta / speed + 500), 2)) * 400;
        const randomRightConstraint = Math.abs(Math.pow(Math.sin(delta / speed + 300), 2)) * 500;
        ctx.fillStyle = hex2rgb(color, opacity);
        ctx.beginPath();
        ctx.moveTo(0, canvas.height / 2 + randomLeft);
        ctx.bezierCurveTo(
          canvas.width / 3,
          randomLeftConstraint,
          (canvas.width / 3) * 2,
          randomRightConstraint,
          canvas.width,
          randomRight
        );
        ctx.lineTo(canvas.width, canvas.height);
        ctx.lineTo(0, canvas.height);
        ctx.lineTo(0, canvas.height / 2 + randomLeft);
        ctx.closePath();
        ctx.fill();
      }
    }
    requestAnimationFrame(drawColoredWave);
  };

  const lineWave = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    const speed = 1500;
    if (wrapperRef.current) canvas.height = wrapperRef.current.offsetHeight / 1.8;

    function drawSingleLineWave(delta: number) {
      if (window.innerWidth > 1024) {
        requestAnimationFrame(drawSingleLineWave);
      }
      canvas.width = window.innerWidth;
      if (ctx) {
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        const randomRight = Math.abs(Math.pow(Math.sin(delta / speed + 15), 2)) * 100 + 100;
        const randomLeft = Math.abs(Math.pow(Math.sin(delta / speed), 2)) * 50 + 50;
        const randomLeftConstraint = Math.abs(Math.pow(Math.sin(delta / speed + 500), 2)) * 200;
        const randomRightConstraint = Math.abs(Math.pow(Math.sin(delta / speed + 300), 2)) * 200;
        ctx.beginPath();
        ctx.moveTo(0, randomRight);
        ctx.bezierCurveTo(
          (canvas.width / 4) * 3,
          randomRightConstraint,
          canvas.width / 2,
          randomLeftConstraint,
          canvas.width,
          randomLeft
        );
        ctx.stroke();
        ctx.fill();
      }
    }
    requestAnimationFrame(drawSingleLineWave);
  };

  const onResize = () => {
    onChange(window.innerWidth < 1024);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (canvasRef.current) coloredWave(canvasRef.current);
    if (canvasLineRef.current) lineWave(canvasLineRef.current);
    window.addEventListener('resize', onResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [canvasRef.current, canvasLineRef.current, wrapperRef.current, isSmallDevice]);

  return isSmallDevice ? null : (
    <div className={styles.wave} ref={wrapperRef}>
      <canvas ref={canvasRef} className={styles.canvas} />
      <canvas ref={canvasLineRef} className={styles.canvas} />
    </div>
  );
};

export default Wave;
